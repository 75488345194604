import './FormMessage.scss'

import { FC, ReactNode, useId } from 'react'

import { cn } from '~/common/ui/utils/cn'

interface FormMessageProps {
  type: 'alert' | 'notice'
  heading: string | ReactNode
  message?: string | ReactNode
}

export const FormMessage: FC<FormMessageProps> = ({
  type,
  heading,
  message,
}) => {
  const headingId = `heading-${useId()}`
  const messageId = `message-${useId()}`

  return (
    <div className='form-error'>
      <div
        className={cn('flash', type)}
        role='alert'
        aria-labelledby={headingId}
        aria-describedby={messageId}
      >
        <span id={headingId}>{heading}</span>
        {message && (
          <p id={messageId} className='message'>
            {message}
          </p>
        )}
      </div>
    </div>
  )
}

export default FormMessage
