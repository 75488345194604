import { FC, useMemo } from 'react'

import SlideReveal from '~/common/ui/utils/SlideReveal'

import FormMessage from './FormMessage'

export interface FlashMessageType {
  type: 'notice' | 'alert'
  message: string
}

export type LoginFormMessageType =
  | FlashMessageType
  | 'BAD_EMAIL'
  | 'BAD_PASSWORD'
  | 'BAD_PASSWORD_OTP'
  | 'ONE_ATTEMPT_LEFT'
  | 'BAD_CSRF'
  | 'UNKNOWN'

const isFlashMessage = (error: unknown): error is FlashMessageType =>
  !!(
    error &&
    (error as FlashMessageType).type &&
    (error as FlashMessageType).message
  )

interface LoginFormMessageProps {
  error: LoginFormMessageType | FlashMessageType | null
}

export const LoginFormMessage: FC<LoginFormMessageProps> = ({ error }) => {
  const message = useMemo(() => {
    if (isFlashMessage(error)) {
      return (
        <FormMessage type={error.type} heading={error.message} message='' />
      )
    }

    switch (error) {
      case 'BAD_EMAIL':
        return (
          <FormMessage
            type='alert'
            heading='Email does not exist'
            message={
              <>
                We couldn’t find an account for that email. Please try again.
                New customer?{' '}
                <a className='message-link' href='/sign-up'>
                  Sign up here.
                </a>
              </>
            }
          />
        )
      case 'BAD_PASSWORD':
        return (
          <FormMessage
            type='alert'
            heading='Incorrect password'
            message={
              <>
                The password you entered is incorrect. Please try again. We can
                help you{' '}
                <a className='message-link' href='/forgot-password/new'>
                  reset your password
                </a>
              </>
            }
          />
        )
      case 'BAD_PASSWORD_OTP':
        return (
          <FormMessage
            type='alert'
            heading='Incorrect password or authentication code'
            message={
              <>
                The password or authentication code you entered is incorrect.
                Please try again. We can help you{' '}
                <a className='message-link' href='/forgot-password/new'>
                  reset your password
                </a>
              </>
            }
          />
        )
      case 'ONE_ATTEMPT_LEFT':
        return (
          <FormMessage
            type='alert'
            heading='You have one more attempt before your account is locked'
          />
        )
      case 'BAD_CSRF':
        return (
          <FormMessage
            type='alert'
            heading='Something went wrong, please refresh'
          />
        )
      case 'UNKNOWN':
      default:
        return false
    }
  }, [error])

  return (
    <SlideReveal reveal={!!message}>
      <div className='pb-4'>{message}</div>
    </SlideReveal>
  )
}

export default LoginFormMessage
