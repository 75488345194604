import axios, { AxiosInstance, RawAxiosRequestConfig } from 'axios'

const meta = document.querySelector('meta[name="csrf-token"]')

const defaultAxiosConfig: RawAxiosRequestConfig = {
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': meta?.getAttribute('content') || '',
  },
}

/**
 * Static class wrapper around axios for making API requests.
 *
 * Having a static class means we can be cheeky and call updateConfig
 * to modify the underlying axios client instance when we want to.
 */
export class ApiClient {
  private static axios = axios.create(defaultAxiosConfig)

  static get: AxiosInstance['get'] = (...params) =>
    ApiClient.axios.get(...params)

  static post: AxiosInstance['post'] = (...params) =>
    ApiClient.axios.post(...params)

  static put: AxiosInstance['put'] = (...params) =>
    ApiClient.axios.put(...params)

  static delete: AxiosInstance['delete'] = (...params) =>
    ApiClient.axios.delete(...params)

  static patch: AxiosInstance['patch'] = (...params) =>
    ApiClient.axios.patch(...params)

  static updateConfig = (config: RawAxiosRequestConfig) => {
    ApiClient.axios = axios.create({ ...defaultAxiosConfig, ...config })
  }

  static updateCsrfToken = (csrfToken: string) => {
    ApiClient.updateConfig({
      headers: { ...defaultAxiosConfig.headers, 'X-CSRF-Token': csrfToken },
    })
  }
}

export default ApiClient
