import '~/common/assetLoader'

import React from 'react'
import { createRoot } from 'react-dom/client'

import { UnauthenticatedRoot } from '../frontend/src/unauthenticated/UnauthenticatedRoot'

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, { logOnDifferentValues: true })
}

window.startUnauthenticatedApp = () => {
  const el = document.createElement('div')
  el.id = 'app'

  document.body.appendChild(el)

  const root = createRoot(el)
  root.render(<UnauthenticatedRoot />)

  const loadingDiv = document.querySelector('.loading-screen')
  loadingDiv.parentNode.removeChild(loadingDiv)
}
