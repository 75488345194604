import './LockoutPage.scss'

import { FC } from 'react'
import { Link } from 'react-router-dom'

import Button from '~/common/ui/buttons/Button'

export const LockoutPage: FC = () => {
  return (
    <div
      className='relative flex min-h-dvh w-full justify-center bg-cover pt-28'
      style={{
        background: 'radial-gradient(circle at 34% 15%, #04a167, teal)',
      }}
    >
      <div className='flex w-full justify-center'>
        <div className='z-10 flex w-full max-w-[465px] flex-col gap-3'>
          <img
            src='/images/FF-logo-stacked-white.svg'
            alt='logo'
            className='w-full max-w-[50%] self-center'
          />
          <div className='information-text'>
            <div className='alert'>Login Failed:</div>
            <div>Sorry, too many password attempts.</div>
            <div className='alert'>
              If you do nothing you will be locked out for 1 hour.
            </div>
            <div>
              However, if you need access, check your email and unlock your
              account by clicking the link, or go back to the Log in page, click
              “Forgot Password” and reset your password.
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <Button
              className='signup-container__button'
              to='/login'
              component={Link}
            >
              Back to Log in
            </Button>
          </div>
        </div>
      </div>
      <img
        src='/images/login-watermark.svg'
        className='absolute bottom-0 right-0 w-[50vw] opacity-10 lg:w-[30vw]'
      />
    </div>
  )
}

export default LockoutPage
