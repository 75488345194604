import { FC } from 'react'

interface SingleSignOnOptionProps {
  iconSrc: string
  iconAlt: string
  iconHeight?: number
  href: string
  text: string
  helperText?: string
}

export const SingleSignOnOption: FC<SingleSignOnOptionProps> = ({
  href,
  iconAlt,
  iconSrc,
  iconHeight,
  text,
  helperText,
}) => {
  return (
    <a className='block text-center no-underline' href={href}>
      <div className='flex items-center rounded border border-white text-white transition-all duration-200 ease-in-out hover:bg-white hover:text-gray-900'>
        <div className='flex h-[60px] w-[60px] items-center justify-center bg-white'>
          <img
            src={iconSrc}
            alt={iconAlt}
            className='h-9'
            style={iconHeight ? { height: iconHeight } : undefined}
          />
        </div>
        <div className='grow text-center text-lg font-semibold leading-6 tracking-normal'>
          <span>{text}</span>
        </div>
      </div>
      {helperText && (
        <span className='pl-10 text-xs italic leading-4 tracking-normal text-white'>
          {helperText}
        </span>
      )}
    </a>
  )
}

export default SingleSignOnOption
