import { VisibilityOff } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { Form, Formik, FormikConfig, FormikHelpers } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { object, string } from 'yup'

import { Location } from '~/common/Location'
import Button from '~/common/ui/buttons/Button'
import { FormControlLabel } from '~/common/ui/forms/FormControlLabel'
import FormikCheckBox from '~/common/ui/forms/Formik/FormikCheckBox'
import { FormikHiddenTextField } from '~/common/ui/forms/Formik/FormikHiddenTextField'
import FormikTextField from '~/common/ui/forms/Formik/FormikTextField'
import { RichTooltip } from '~/common/ui/RichTooltip'
import { SlideReveal } from '~/common/ui/utils/SlideReveal'
import { bugsnagClient } from '~/common/utils/Bugsnag'
import { ApiClient } from '~/unauthenticated/api'
import {
  FlashMessageType,
  LoginFormMessage,
  LoginFormMessageType,
} from '~/unauthenticated/components'

import {
  UnsupportedBrowserPage,
  useIsUnsupportedBrowser,
} from '../UnsupportedBrowserPage/UnsupportedBrowserPage'
import { SingleSignOnOption } from './SingleSignOn'

interface LoginFormData {
  email: string
  password: string
  otpAttempt: string
  otpRememberThisDevice: boolean
}

function validateStatus(status: number) {
  return [200, 400, 401, 422, 423].includes(status)
}

type ErrorData =
  | {
      error: string
      identityProvider: never
    }
  | {
      error: 'Your account is using SSO'
      identityProvider: string
    }

function handle401Error(
  data: ErrorData,
  setValues: FormikHelpers<LoginFormData>['setValues'],
  setErrors: FormikHelpers<LoginFormData>['setErrors'],
  setFormMessage: ReturnType<typeof useState<LoginFormMessageType | null>>[1],
  navigate: NavigateFunction,
  usingOtp: boolean,
) {
  const genericPasswordError = {
    password: 'Invalid password',
  }
  const genericOtpError = {
    password: 'Invalid password or code',
    otpAttempt: 'Invalid password or code',
  }

  switch (data?.error) {
    case 'Email does not exist':
      setFormMessage('BAD_EMAIL')
      setErrors({
        email: 'Invalid email',
      })
      return
    case 'Incorrect password':
      if (usingOtp) {
        setFormMessage('BAD_PASSWORD_OTP')
        setErrors(genericOtpError)
      } else {
        setFormMessage('BAD_PASSWORD')
        setErrors(genericPasswordError)
      }
      return
    case 'You have one more attempt before your account is locked':
      setFormMessage('ONE_ATTEMPT_LEFT')
      if (usingOtp) {
        setErrors(genericOtpError)
      } else {
        setErrors(genericPasswordError)
      }
      return
    case 'Your account is locked':
      navigate('/unlock/new')
      return
    case 'Your account is using SSO':
      setFormMessage({
        type: 'alert',
        message: `Attention: You are using Sign in with ${data.identityProvider || 'third party'}`,
      })
      setValues(
        {
          email: '',
          password: '',
          otpAttempt: '',
          otpRememberThisDevice: false,
        },
        false,
      )
      return
    default:
      setFormMessage('UNKNOWN')
      if (usingOtp) {
        setErrors({
          email: 'Invalid email, password or code',
          password: 'Invalid email, password or code',
          otpAttempt: 'Invalid email, password or code',
        })
      } else {
        setErrors({
          email: 'Invalid email or password',
          password: 'Invalid email or password',
        })
      }
  }
}

export const LoginPage: FC = () => {
  const [formMessage, setFormMessage] = useState<LoginFormMessageType | null>(
    null,
  )
  const navigate = useNavigate()
  const [capsLockIsOn, setCapsLockIsOn] = useState(false)
  const [redirecting, setRedirecting] = useState(false)
  const unsupportedBrowser = useIsUnsupportedBrowser()
  const [usingOtp, setUsingOtp] = useState(false)

  const validationSchema = useMemo(
    () =>
      object().shape({
        email: string().required('Email is required'),
        password: string().required('Password is required'),
        otpAttempt: usingOtp
          ? string().required('Code is required')
          : string().notRequired(),
      }),
    [usingOtp],
  )

  useEffect(() => {
    // Hook to detect caps lock key
    const handleKeyDown = (event: KeyboardEvent) => {
      event?.getModifierState &&
        setCapsLockIsOn(event.getModifierState('CapsLock'))
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyDown)
    }
  }, [])

  const handleSubmit: FormikConfig<LoginFormData>['onSubmit'] = useCallback(
    async (
      { otpAttempt, otpRememberThisDevice, ...standardValues },
      { setErrors, setValues },
    ) => {
      setFormMessage(null)
      const values = usingOtp
        ? {
            user: { ...standardValues, otp_attempt: otpAttempt },
            otp_remember_this_device: otpRememberThisDevice,
          }
        : { user: standardValues }
      const response = await ApiClient.post('/login', values, {
        validateStatus,
      }).catch((error) => {
        setErrors({
          email: 'Unknown login error',
          password: 'Unknown login error',
          otpAttempt: 'Unknown login error',
        })
        setValues(
          {
            email: values.user.email,
            password: '',
            otpAttempt: '',
            otpRememberThisDevice: false,
          },
          false,
        )
        bugsnagClient.notify(error)
      })

      switch (response?.status) {
        case 200:
          // Success, perform http redirect
          Location.assign(response.data?.location ?? '/')
          setRedirecting(true)
          break
        case 400:
          setUsingOtp(true)
          break
        case 401:
          // Unauthorized / bad credentials, show error message
          handle401Error(
            response.data,
            setValues,
            setErrors,
            setFormMessage,
            navigate,
            usingOtp,
          )
          setValues(
            {
              email: values.user.email,
              password: '',
              otpAttempt: '',
              otpRememberThisDevice: false,
            },
            false,
          )
          break
        case 422:
          // CSRF token error
          setValues(
            {
              email: values.user.email,
              password: '',
              otpAttempt: '',
              otpRememberThisDevice: false,
            },
            false,
          )
          setFormMessage('BAD_CSRF')
          setErrors({
            email: 'Unable to login',
            password: 'Unable to login',
          })
          break
        case 423:
          // Locked out, redirect to unlock page
          Location.assign(response.data?.location ?? '/')
          setRedirecting(true)
          break
      }
    },
    [navigate, usingOtp],
  )

  useEffect(() => {
    // Hook to read rails flash message and display alert
    const meta = document.querySelector('meta[name="flash"]')
    if (meta) {
      try {
        const flash = JSON.parse(
          meta.getAttribute('content') ?? '',
        ) as FlashMessageType[]
        if (flash.length) {
          setFormMessage(flash[0])
        }
      } catch (error) {
        bugsnagClient.notify(error)
      } finally {
        meta?.remove()
      }
    }
  }, [])

  if (unsupportedBrowser) {
    return <UnsupportedBrowserPage />
  }

  return (
    <div
      className='relative flex min-h-dvh w-full justify-center bg-cover pt-28'
      style={{
        background: 'radial-gradient(circle at 34% 15%, #04a167, teal)',
      }}
    >
      <div className='flex w-full justify-center'>
        <div className='z-10 flex w-full max-w-[465px] flex-col gap-3 px-5'>
          <img
            src='/images/FF-logo-stacked-white.svg'
            alt='logo'
            className='w-full max-w-[53%] self-center'
          />

          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              email: '',
              password: '',
              otpAttempt: '',
              otpRememberThisDevice: false,
            }}
            validationSchema={validationSchema}
          >
            {({ errors, isSubmitting }) => {
              return (
                <Form>
                  <div className='flex flex-col gap-6 pt-4'>
                    <SlideReveal reveal={!usingOtp}>
                      <div className='flex flex-col gap-3'>
                        <SingleSignOnOption
                          href='/users/sso/new?login_method=GoogleOAuth'
                          iconSrc='/images/google-g-logo.svg'
                          iconAlt='Google logo'
                          iconHeight={40}
                          text='Sign in with Google'
                        />
                        <SingleSignOnOption
                          href='/users/sso/new?login_method=MicrosoftOAuth'
                          iconSrc='/images/microsoft-logo.svg'
                          iconAlt='Microsoft logo'
                          text='Sign in with Microsoft*'
                          helperText='*Must be personal Microsoft account'
                        />
                      </div>
                    </SlideReveal>
                    <div>
                      <LoginFormMessage error={formMessage} />
                      <div className='flex flex-col gap-2 rounded bg-white px-5 py-6'>
                        <FormikTextField
                          id='loginEmail'
                          name='email'
                          label='Email address'
                          placeholder='Enter email address'
                          type='email'
                          variant='outlined'
                          error={!!errors.email}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  edge='end'
                                  // Want to add hidden icon so that browser password decorations (lastpass, etc)
                                  // are vertically aligned with the password field
                                  style={{ visibility: 'hidden' }}
                                >
                                  <VisibilityOff />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                        <FormikHiddenTextField
                          id='loginPassword'
                          name='password'
                          label='Password'
                          placeholder='Enter password'
                          variant='outlined'
                          helperText={
                            capsLockIsOn ? 'Warning: Caps lock enabled' : ' '
                          }
                          error={!!errors.password}
                          fullWidth
                          visibilityToggle
                        />
                        <SlideReveal reveal={usingOtp}>
                          <FormikTextField
                            id='otpAttempt'
                            name='otpAttempt'
                            label='Enter the code displayed in your authenticator app'
                            placeholder='Code'
                            type='text'
                            variant='outlined'
                            error={!!errors.otpAttempt}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    // Want to add hidden icon so that browser password decorations (lastpass, etc)
                                    // are vertically aligned with the password field
                                    style={{ visibility: 'hidden' }}
                                  >
                                    <VisibilityOff />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </SlideReveal>
                      </div>
                      <SlideReveal reveal={usingOtp}>
                        <div className='flex items-center justify-between'>
                          <FormControlLabel
                            control={
                              <FormikCheckBox
                                name='otpRememberThisDevice'
                                variant='filled'
                                color='white'
                              />
                            }
                            label={
                              <span className='-ml-1 flex items-center gap-1 text-sm font-thin text-white'>
                                Trust this device for 30 days{' '}
                                <RichTooltip variant='info-light'>
                                  Not for use on shared devices. Enabling this
                                  will disable the MFA code prompt for this
                                  device for 30 days.
                                </RichTooltip>
                              </span>
                            }
                            className='pl-1'
                          />
                          <a
                            className='text-sm font-bold text-white no-underline hover:text-white hover:!underline'
                            target='_blank'
                            href='https://help.farmfocus.nz/en/articles/9438311-multi-factor-authentication-mfa'
                            rel='noreferrer'
                          >
                            Don’t have your device?
                          </a>
                        </div>
                      </SlideReveal>
                    </div>

                    <div className='flex flex-col gap-1 pb-6'>
                      <Button
                        type='submit'
                        className='signup-container__button'
                        waitingText='Logging in'
                        disabled={isSubmitting || redirecting}
                        waiting={isSubmitting || redirecting}
                      >
                        Log in
                      </Button>
                      <div className='flex justify-between'>
                        <span className='inline-flex text-sm text-white'>
                          New here?&nbsp;
                          <a
                            href='/sign-up'
                            className='font-bold text-white no-underline hover:text-white hover:!underline'
                            data-e2e-ci='sign-up'
                          >
                            Sign up now
                          </a>
                        </span>
                        <span className='inline-flex text-sm font-semibold text-white'>
                          <a
                            href='/forgot-password/new'
                            className='font-bold text-white no-underline hover:text-white hover:!underline'
                          >
                            Forgot password?
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <img
        src='/images/login-watermark.svg'
        className='absolute bottom-0 right-0 w-[50vw] opacity-10 lg:w-[30vw]'
      />
    </div>
  )
}

export default LoginPage
