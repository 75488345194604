import { Visibility, VisibilityOff } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'
import { FC, useCallback, useState } from 'react'

import { colors } from '~/tailwindResolver'

import IconButton from '../../buttons/IconButton'
import FormikTextField, { FormikTextFieldProps } from './FormikTextField'

interface FormikHiddenTextFieldProps
  extends Omit<FormikTextFieldProps, 'type'> {
  visibilityToggle?: boolean
}

/**
 * Similar in functionality to `HiddenTextField` but with `FormikTextField` as the base.
 */
export const FormikHiddenTextField: FC<FormikHiddenTextFieldProps> = ({
  visibilityToggle = false,
  InputProps = {},
  ...rest
}) => {
  const [showValue, setShowValue] = useState(false)

  const handleVisibilityToggle = useCallback(() => {
    setShowValue((prev) => !prev)
  }, [])

  return (
    <FormikTextField
      type={visibilityToggle && showValue ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        ...(visibilityToggle
          ? {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleVisibilityToggle}
                    edge='end'
                    htmlColor={colors.gray[500]}
                  >
                    {showValue ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}),
      }}
      {...rest}
    />
  )
}

export default FormikHiddenTextField
