import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'

import { cn } from './cn'

interface SlideRevealProps {
  /**
   * Toggle visibility of the content
   */
  reveal?: boolean
  /**
   * Custom classes for the transition animation. Setting this will override the default transition
   * so you will need to provide all the necessary classes for the animation
   *
   * @default transition-all duration-300 ease-in-out
   */
  transitionClassName?: string
  /**
   * Custom styles for the transition animation.
   */
  transitionStyle?: React.CSSProperties
  /**
   * The delay in milliseconds before the content is unrendered after being hidden.
   * Prevents content from disappearing while still animating closed. Should match
   * the duration of the closing animation.
   *
   * @default 300 // Matches default animation duration
   */
  unrenderDelay?: number
}

/**
 * **_Consider using MUI's `<Collapse>` component instead_**
 *
 * Animates the reveal and hiding of content with a slide effect.
 * Works a bit better with content that changes height than the MUI <Collapse>
 * component which is more well suited to static content.
 *
 * When closing, the content will be unrendered after the animation has completed.
 */
export const SlideReveal: FC<PropsWithChildren<SlideRevealProps>> = ({
  reveal = false,
  transitionClassName = 'transition-all duration-300 ease-in-out',
  transitionStyle = {},
  unrenderDelay = 300,
  children,
}) => {
  const [height, setHeight] = useState(reveal ? 'auto' : '0px')
  const [renderContent, setRenderContent] = useState(reveal)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(`${entry.contentRect.height}px`)
      }
    })
    observer.observe(contentRef.current as Element)
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (reveal || unrenderDelay === 0) {
      setRenderContent(reveal)
    } else {
      const timeout = setTimeout(() => {
        setRenderContent(false)
      }, unrenderDelay)

      return () => clearTimeout(timeout)
    }
  }, [reveal, unrenderDelay])

  return (
    <div
      style={{
        ...transitionStyle,
        height: reveal ? height : 0,
      }}
      className={cn('overflow-y-clip', transitionClassName)}
    >
      <div ref={contentRef}>{renderContent && children}</div>
    </div>
  )
}

export default SlideReveal
