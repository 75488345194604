import './UnauthenticatedRoot.scss'

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { StrictMode, useCallback } from 'react'
import { FC } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { RouterProvider, RouterProviderProps } from 'react-router-dom'

import bugsnagClient from '~/common/utils/Bugsnag'
import { ErrorBoundaryWithoutRouter } from '~/reports/Common/ErrorBoundary'
import { router as defaultRouter } from '~/unauthenticated/routes/router'

import muiTheme from '../theme/mui/muiTheme'
import { ApiClient } from './api'

const USER_IDLE_TIMEOUT = 1_000 * 60 // 1 minute

interface RootProps {
  router: RouterProviderProps['router']
}

export const UnauthenticatedRoot: FC<RootProps> = ({ router }) => {
  const onActive = useCallback(async () => {
    // Fetch a new CSRF token when the user becomes active after being idle
    const response = await ApiClient.get('/csrf/token').catch((e) => {
      // notify silently
      bugsnagClient.notify(e)
      return
    })
    if (!response || !response.data?.csrf_token) return

    ApiClient.updateCsrfToken(response.data.csrf_token)
  }, [])

  useIdleTimer({
    timeout: USER_IDLE_TIMEOUT,
    onActive,
  })

  return (
    <StrictMode>
      <MuiThemeProvider theme={muiTheme}>
        <ErrorBoundaryWithoutRouter>
          <RouterProvider router={router || defaultRouter()} />
        </ErrorBoundaryWithoutRouter>
      </MuiThemeProvider>
    </StrictMode>
  )
}

export default UnauthenticatedRoot
