import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { FourZeroFour } from '~/errors/404'
import { LockoutPage, LoginPage } from '~/unauthenticated/pages'

export const router = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/unlock' element={<LockoutPage />} />
        <Route path='/unlock/new' element={<LockoutPage />} />
        <Route
          path='*'
          element={<FourZeroFour returnPath='/login' returnPathName='log in' />}
        />
      </>,
    ),
  )

export default router
