import './UnsupportedBrowserPage.scss'

import { detect as detectBrowser } from 'detect-browser'
import { FC, useEffect, useState } from 'react'

const BROWSER_CONSTANTS = {
  chrome: 'chrome',
  firefox: 'firefox',
  safari: 'safari',
  edge: 'edge',
}

const OS_CONSTANTS = {
  macOS: 'Mac OS',
}

type Browser = ReturnType<typeof detectBrowser>

export const isMac = (browser: Browser): boolean => {
  return browser?.os === OS_CONSTANTS.macOS
}

const isSafari = (browser: Browser): boolean => {
  return browser?.name === BROWSER_CONSTANTS.safari
}

const isFirefox = (browser: Browser): boolean => {
  return browser?.name === BROWSER_CONSTANTS.firefox
}

const isChrome = (browser: Browser): boolean => {
  return browser?.name === BROWSER_CONSTANTS.chrome
}

const isEdge = (browser: Browser): boolean | undefined => {
  return browser?.name.includes(BROWSER_CONSTANTS.edge)
}

const isAndroid = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor
  return /android/i.test(userAgent)
}

const isiOS = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor
  return /iPad|iPhone|iPod/.test(userAgent)
}

// Defines "mobile" as any iOS or Android device (tablet or phone)
const isMobile = (): boolean => {
  return isAndroid() || isiOS()
}

// Returns true if the user is not using either Safari, Firefox, Chrome or Edge and the user is not
// on a mobile device
const isDegradedBrowser = (browser: Browser): boolean => {
  return (
    !isSafari(browser) &&
    !isFirefox(browser) &&
    !isChrome(browser) &&
    !isEdge(browser) &&
    !isMobile()
  )
}

export const useIsUnsupportedBrowser = () => {
  const [unsupportedBrowser, setUnsupportedBrowser] = useState(false)

  useEffect(() => {
    const browser = detectBrowser()

    setUnsupportedBrowser(isDegradedBrowser(browser))
  }, [])

  return unsupportedBrowser
}

export const UnsupportedBrowserPage: FC = () => {
  const [mac, setMac] = useState(false)

  useEffect(() => {
    setMac(isMac(detectBrowser()))
  }, [])

  return (
    <div className='unsupported-browser-background relative flex min-h-dvh w-full justify-center bg-cover pt-28'>
      <div className='browsersContainer browsersPanel'>
        <div className='browsersHeading'>
          <img
            className='farm-focus-logo'
            src='/images/FF-logo-stacked-black-transparent-bg.png'
          />
        </div>
        <p className='browsersHeading heading-text'>
          Please upgrade your browser to use Farm Focus
        </p>
        <div className='content-text'>
          We build Farm Focus using up-to-date technology, which makes it
          faster, more secure and easier to use. Unfortunately, your browser
          doesn’t support those features. Download one of the compatible
          browsers and you’ll be on your way:
        </div>
        <div className='browsers'>
          <a
            href='https://www.google.com/chrome/'
            target='_blank'
            className='browserLink'
            rel='noreferrer'
          >
            <img className='browserLogo' src='/images/chrome-logo@2x.png' />
            <br />
            Chrome
          </a>
          <a
            href='https://www.mozilla.org/en-US/firefox/new/'
            target='_blank'
            className='browserLink'
            rel='noreferrer'
          >
            <img className='browserLogo' src='/images/firefox-logo@2x.png' />
            <br />
            Firefox
          </a>
          {mac && (
            <a
              href='https://www.apple.com/nz/safari/'
              target='_blank'
              className='browserLink safariBrowser'
              rel='noreferrer'
            >
              <img className='browserLogo' src='/images/safari-logo@2x.png' />
              <br />
              Safari
            </a>
          )}
          <a
            href='https://www.microsoft.com/en-us/edge'
            target='_blank'
            className='browserLink'
            rel='noreferrer'
          >
            <img className='browserLogo' src='/images/edge-logo@2x.png' />
            <br />
            Edge
          </a>
        </div>
        <p className='content-text'>
          Got questions? Contact our{' '}
          <a
            href='https://www.farmfocus.co.nz/support/contact-us/'
            target='_blank'
            rel='noopener noreferrer'
          >
            support team
          </a>{' '}
          for assistance.
        </p>
      </div>
      <img
        src='/images/login-watermark.svg'
        className='absolute bottom-0 right-0 w-[50vw] opacity-10 lg:w-[30vw]'
      />
    </div>
  )
}

export default UnsupportedBrowserPage
